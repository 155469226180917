<template>
  <div></div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {};
  },
  name: "Dashboard",
  components: {},
  computed: {
    ...mapGetters(["user"]),
    isLoggedIn: function () {
      return localStorage.getItem("token");
    },
  },
  created() {
    if (this.isLoggedIn) {
      this.succesMessage();
    } else {
      window.location.href = "/";
    }
  },
  methods: {
    ...mapActions([
      "getUserplanlist",
      "addToCart12",
      "PayuPaymentDetail",
      "PaymentResponceFailed",
    ]),

    succesMessage() {
      // alert()
      let message = "";
      let title = "Payment Failed";
      let type = "error";
      this.$alert(message, title, type).then(() => {
        window.location.href = "/";
      });
    },
  },
};
</script>